.column {
  display: flex;
  flex-direction: column;
}

.section {
  padding: 2rem 1.5rem !important;
}

.header {
  box-shadow: 0px -6px 6px 4px #00000030;
  padding: 1rem;
  padding-top: 1.5rem;
}

input[type="radio"] {
  margin-right: 6px;
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.sidebar {
  border-right: 1px solid #d8d8d8;
  padding-left: 1rem;
  padding-top: 1rem;

  & > li {
    padding: 0.5rem 0;
    > a {
      color: #85898f;
    }
  }
}
a.active {
  color: #0fb9c3 !important;
}
